<template>
  <form @submit.prevent="saveContactDetails" class="flex flex-wrap py-4">
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Name</label>
      <input
        v-model="contactDetails.name"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="Company Name..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Website</label>
      <input
        v-model="contactDetails.website"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="Company Name..."
      />
    </div>
    <div class="w-full">
      <button
        type="button"
        @click="saveContactDetails"
        class="float-right mt-5 flex items-center bg-green-200 hover:bg-green-300 text-secondary py-1 px-3 rounded border border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Save</span>
      </button>
    </div>
  </form>
</template>

<script>
import notificationMixin from "@/mixins/notificationMixin";

const Toggle = () => import("@/components/Toggle.vue");

export default {
  name: "EditContactDetails",
  mixins: [notificationMixin],
  components: {
    Toggle,
  },
  props: {
    contactDetails: {
      required: true,
      type: Object,
    },
  },
  methods: {
    saveContactDetails: async function() {
      try {
        debugger;
        await this.ContactService.updateContact(this.contactDetails.contact_id, this.contactDetails);
        this.notifySuccess("Contact updated successfully");
        this.$emit("refreshContact");
      } catch (error) {
        this.notifyError("Couldn't update contact");
        console.error(error);
      }
    },
  },
};
</script>
